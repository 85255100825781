import * as React from 'react'
import { graphql } from 'gatsby'

import { Container, Grid } from '@mui/material';
import Breadcrumbs from '../components/Breadcrumbs';
import RichText from '../components/RichText';
import PageHeader from '../components/layout/PageHeader';
import Content from '../components/Content';
import Seo from '../components/Seo';
import buildMetaData from '../services/buildMetaData';
import buildOgpTags from '../services/buildOgpTags';
import addToSchema from '../services/addToSchema';

const TeamPageTemplate = ({ data, pageContext }) => {
  const page = data.page || {}

  return <>
    <br />
    <Container maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Breadcrumbs breadcrumbs={pageContext.breadcrumbs} />
        </Grid>
        <Grid item xs={12}>
          <RichText
            sx={{
              textAlign: 'center'
            }}
            content={page.data.title.richText}
          />
        </Grid>
        <Grid item xs={12}>
          <PageHeader icon={page.data.icon} subtitle={page.data.subtitle} />
        </Grid>
        <Grid item xs={12} sx={{
          display: 'flex',
          justifyContent: 'center'
        }}>
          <RichText sx={{
            textAlign: 'left',
            width: {
              xs: 'auto',
              md: '60%'
            }
          }}
            content={page.data.description.richText} />
        </Grid>
      </Grid>
    </Container>
    <Content doc={page} />
  </>

}

export const Head = ({ data, pageContext }) => {
  const page = data.page || {}

  const meta = buildMetaData(page)
  const ogp = buildOgpTags(page, pageContext)

  let schema = page.data?.schema_json?.text || ""

  // FAQ
  if (page.data?.faq?.length) {
    schema = addToSchema(schema, {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": page.data.faq.map(({ question: faq }) => (
        {
          "@type": "Question",
          "name": faq.document.data.question.text.replace(/(\r\n|\n|\r)/gm, ""),
          "acceptedAnswer": {
            "@type": "Answer",
            "text": faq.document.data.answer.text.replace(/(\r\n|\n|\r)/gm, "")
          }
        }
      ))
    })
  }

  schema = addToSchema(schema, {
    "@graph": [
      ...page.data.body.find(b => b.slice_type === 'team_members').items
        .filter(({ team_member: tm }) => tm.document)
        .map(({ team_member: tm }) => ({
          "@context": "https://schema.org/",
          "@type": "Physician",
          "name": tm.document.data.title.text,
          "medicalSpecialty": "https://schema.org/Dentist",
          "availableService": tm.document.data.services
            .filter(({ service }) => service.document)
            .map(({ service }) => service.document.data.title.text),
          "telephone": "+40773856287",
          "priceRange": "$$",
          "image": {
            "@type": "ImageObject",
            "Name": tm.document.data.image.alt,
            "height": tm.document.data.image.dimensions.height,
            "width": tm.document.data.image.dimensions.width,
            "url": tm.document.data.image.gatsbyImageData.images.fallback.src
          }
        }))
    ]
  })

  return <Seo schema={schema} ogp={ogp} meta={meta} breadcrumbs={pageContext.breadcrumbs} path={pageContext.url} />
}

export const query = graphql`
  query teamPageQuery($lang: String) {
    page: prismicTeamPage(lang: { eq: $lang }) {
      alternate_languages {
        uid
        type
        lang
      }
      lang
      url
      type
      data {           
        meta_title
        meta_description
        meta_keywords
        ogp_tags {
          content
          property
        }
        schema_json {
          text
        }
        faq {
          question {
            document {
              ... on PrismicFaq {
                id
                data {
                  answer {
                    text
                  }
                  question {
                    text
                  }
                }
              }
            }
          }
        }
        title {
          richText
        }
        subtitle {
          richText
        }
        icon {
          alt
          gatsbyImageData(width: 100)
        }
        description {
          richText
        }        
        body {




          ... on PrismicTeamPageDataBodyTeamMembers {
            id
            slice_type
            primary {
              team_members_title {
                richText
              }
            }
            items {
              team_member {
                document {
                  ... on PrismicTeamMember {
                    id
                    uid
                    type
                    lang
                    data {
                      title {
                        text
                      }
                      description {
                        richText
                      }
                      image {
                        alt
                        dimensions{
                          height
                          width
                        }
                        gatsbyImageData(layout: FULL_WIDTH)
                      }
                      specialisations {
                        specialisation {
                          document {
                            ... on PrismicSpecialisation {
                              id
                              uid
                              data {
                                color
                                title {
                                  text
                                }
                              }
                            }
                          }
                        }
                      }
                      services {
                        service {
                          document {
                            ... on PrismicService {
                              id
                              uid
                              lang
                              type
                              data {
                                title {
                                  text
                                }
                                icon {
                                  alt
                                  gatsbyImageData(width: 20)
                                }
                              }
                            }
                          }
                        }
                      }

                    }
                  }
                }
              }
            }
          }




        }
      }
    }
  }
`

export default TeamPageTemplate